import { from, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap, take, tap, catchError, mergeMap, map } from 'rxjs/operators';
import { Instance, InstanceValues, ReducedContainer, ContainerValues } from '../models';
import { BaseService } from '../../core/services/base-service.service';

@Injectable({
  providedIn: 'root'
})
export class OriginationsService extends BaseService {

  serverErrorCode = 500;
  loaderName: string;
  isInbox = false;
  protected baseUrl = window['__env'].APIS.ORIGINATIONS;

  runPartialFunction(instanceId: string, containerId: string, partialName, agentId: string): Observable<any> {

    // Url to run partial function using the new Instances API endpoint
    const runFunctionEndpoint = `${containerId}:executePartialFunction`;

    const baseUrl = `${this._env.APIS.INSTANCES_API}/organizations/${this._env.APIS.TENANTID}/agents/${agentId}/instances/${instanceId.toUpperCase()}/containers`;
    const body = { 'partialFunctionName': partialName };

    return super.post(runFunctionEndpoint, body, { baseUrl: baseUrl, loader: true });
  }

  getInstance(instanceId: string, agentId: string): Observable<Instance> {

    // Url to get the instance using the new Instances API endpoint
    const getInstanceUrl = `${this._env.APIS.INSTANCES_API}/organizations/${this._env.APIS.TENANTID}/agents/${agentId}/instances/${instanceId.toUpperCase()}`;

    return super.get(`?isInbox=${this.isInbox}`, { baseUrl: getInstanceUrl }).pipe(

      tap(response => {

        mergeMap(_ => from(response().pipe(catchError(error => of(`Error: ${error}`)))))

        if (!response && response.code !== this.serverErrorCode) {

          setTimeout(() => {

            this.getInstance(instanceId, agentId);
          });
        }
      })
    ).pipe(map(result => {

      // Need to check if the result contains values property, because the new instances response don't have it
      if (result.values === undefined) {

        result = this.buildInstanceStructure(result, instanceId, agentId);
      }

      return result;
    }));
  }

  getPDFOriginationDocument(idDocument: string): Observable<any> {

    return from(this._auth.accessToken).pipe(
      take(1),
      switchMap(
        (token) => {

          const httpClient = new HttpClient(this._httpBackend);
          const headers = new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Ocp-Apim-Subscription-Key': window['__env'].SUBSCRIPTION_KEY
          });
          return httpClient.get(`${this.baseUrl}/Template/${idDocument}/GetPDF`, { headers: headers, responseType: 'blob' });
        }
      )
    )
  }

  getReducedContainer(instanceId: string, containerId: string, agentId: string, isInbox: boolean = false): Observable<any> {

    // Url to get the container using the new Instances API endpoint
    const getContainerUrl = `${this._env.APIS.INSTANCES_API}/organizations/${this._env.APIS.TENANTID}/agents/${agentId}/instances/${instanceId.toUpperCase()}/containers`;

    return super.get(containerId, { baseUrl: getContainerUrl, loader: true }, { isInbox }).pipe(map(result => {

      result = this.buildContainerStructure(result);

      return result;
    }));
  }

  createInstance(definitionId: string): Observable<any> {

    // Url to create instance using the new Instances API endpoint
    const createInstanceUrl = `${this._env.APIS.INSTANCES_API}/organizations/${this._env.APIS.TENANTID}/products/${definitionId}`;

    return super.post(`instances`,
      {
        definitionId: definitionId
      },
      {
        baseUrl: createInstanceUrl,
        loaderName: this.loaderName
      }
    );
  }

  partialSave(instanceId: string, containerId: string, values: any, loaderName?: string): Observable<any> {

    let result: Observable<any>;

    const baseUrl = `${this._env.APIS.PARTIAL_SAVE}/organizations/${this._env.APIS.TENANTID}/agents/${this._auth.userInfo.uid}/instances/${instanceId.toUpperCase()}/containers`;

    result = super.patch(`${containerId}`, values, { baseUrl: baseUrl, loaderName }).pipe(
      map(() => {
        return {
          // set status to 0 to indicate success and it is used in the component to show the success message
          status: 0
        }
      }),
      catchError(() => {
        return of({
          // set status 3 to indicate an error and not saved
          status: 3
        })
      })
    );

    return result;
  }

  processInstance(instanceId: string, containerId: string, agentId: string): Observable<any> {

    // Url to process instance using the new Instances API endpoint
    const baseUrl = `${this._env.APIS.INSTANCES_API}/organizations/${this._env.APIS.TENANTID}/agents/${agentId}/instances/${instanceId.toUpperCase()}/containers`;
    const processEndpoint = `${containerId}:process`;

    return super.post(processEndpoint, {}, { baseUrl: baseUrl, loaderName: this.loaderName })
      .pipe(
        map(() => {
          return {
            // set status to 0 to indicate success and it is used in the component to show the success message
            status: 0
          }
        }),
        catchError(() => {
          return of({
            // set status 3 to indicate an error and not processed
            status: 3
          })
        })
      );
  }

  getCatalogByParentId(resource: string, parentId: string, instanceId?: string, dataType?: string): Observable<any> {
    const loaderConfig = { baseUrl: this.baseUrl, loader: false };
    if (instanceId) {
      return super.get(`catalogs/${resource}?instanceId=${instanceId}&dataType=${dataType}&parentKey=${parentId}`,
        loaderConfig);
    } else {
      return super.get(`catalogs/${resource}?parentKey=${parentId}`, loaderConfig);
    }
  }

  getCatalog(resource: string, instanceId?: string, dataType?: string): Observable<any> {
    const loaderConfig = { baseUrl: this.baseUrl, loader: false };
    if (instanceId) {
      return super.get(`catalogs/${resource}?instanceId=${instanceId}&dataType=${dataType}`, loaderConfig);
    } else {
      return super.get(`catalogs/${resource}`, loaderConfig);
    }
  }

  getByUserProfile(instanceId: string): Observable<any> {
    return super.get(`instances/GetByUserProfile/${instanceId.toUpperCase()}`);
  }

  getUTFDate(): Observable<any> {
    return super.get(`Instances/datetime/utc`);
  }

  getTreeByUserProfile(instanceId: string): Observable<any> {
    return super.get(`instances/GetTreeByUserProfile/${instanceId.toUpperCase()}/?buzon=${this.isInbox}`, { loader: true });
  }

  assign(instanceId: string): Observable<any> {

    // Url to assign instance from inbox using the new Instances API endpoint
    const baseUrl = `${this._env.APIS.INSTANCES_API}/organizations/${this._env.APIS.TENANTID}/agents/${this._auth.userInfo.uid}/instances`;
    const assignmentEndpoint = `${instanceId.toUpperCase()}:inboxAssignment`;

    const loaderName = 'assignDocumentToAgent';

    return super.post(assignmentEndpoint, {}, { baseUrl: baseUrl, loaderName });
  }

  private buildInstanceStructure(instance: any, instanceId: string, agentId: string) {

    let newInstance = new Instance();
    let values = new InstanceValues();

    // Set always status 0, because this is evaluated with old instances response when set values in change status logic (0 = OK)
    // this is possible because the new instances response all the time is successful otherwise this is caught by the mergeMap
    newInstance.status = 0;
    newInstance.instanceId = instanceId;
    newInstance.agentId = agentId;
    newInstance.isOwner = instance.isOwner;

    values.schemaTitle = instance.schemaTitle;
    values.headerValues = instance.headerValues;
    values.structure = instance.structure;
    values.dataTypes = instance.dataTypes;
    values.containers = instance.containers;
    values.documentId = instance.documentId;
    values.prefix = instance.prefix;
    values.suffix = instance.suffix;
    values.uid = instance.uid;
    values.initialDate = instance.initialDate;

    newInstance.values = values;

    return newInstance;
  }

  private buildContainerStructure(container: any) {

    let newContainer = new ReducedContainer();
    let values = new ContainerValues();

    newContainer.containerId = container.containerId;
    newContainer.customStatusContainer = container.customStatusContainer;
    newContainer.instanceId = container.instanceId;
    newContainer.isHiddenClose = container.isHiddenClose;
    newContainer.status = container.status;
    newContainer.tenantId = container.tenantId;

    values.containerId = container.containerId;
    values.customStatus = container.customStatus;
    values.customStatusInfo = container.customStatusInfo;
    values.data = container.data;
    values.dataFront = container.dataFront;
    values.isHiddenClose = container.isHiddenClose;
    values.status = container.status;
    values.title = container.title;

    newContainer.values = values;

    return newContainer;
  }
}
